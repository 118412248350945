/**
 * Combined by jsDelivr.
 * Original files:
 * - /npm/semantic-ui@2.4.2/dist/components/button.min.css
 * - /npm/semantic-ui@2.4.2/dist/components/form.min.css
 * - /npm/semantic-ui@2.4.2/dist/components/popup.min.css
 * - /npm/semantic-ui@2.4.2/dist/components/input.min.css
 * - /npm/semantic-ui@2.4.2/dist/components/dropdown.min.css
 * - /npm/semantic-ui@2.4.2/dist/components/message.min.css
 * - /npm/semantic-ui@2.4.2/dist/components/transition.min.css
 * - /npm/semantic-ui@2.4.2/dist/components/checkbox.min.css
 * - /npm/semantic-ui@2.4.2/dist/components/divider.min.css
 * - /npm/semantic-ui@2.4.2/dist/components/segment.min.css
 *
 * Do NOT use SRI with dynamically generated files! More information: https://www.jsdelivr.com/using-sri-with-dynamic-files
 */
